import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav } from 'react-bootstrap';
import './Navbar.css';

const Navbar = () => {
    const location = useLocation();

    return (
        <BootstrapNavbar collapseOnSelect expand="lg" className="navbar-styling" style={{ fontFamily: 'Montserrat, sans-serif' }}>
            <div className="navbar-brand logo logo-container" to="/">
                <img src="HMR_clear.png" alt="logo" height={70} width={70}></img>
                <Link to="https://www.instagram.com/hmr.decor/">
                    <img src="instagram.png" alt="instagram link" className='social-icons'></img>
                </Link>
                <Link to="https://www.tiktok.com/@hmr.decor">
                    <img src="tik-tok.png" alt="instagram link" className='social-icons' />
                </Link>
            </div>
            <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
            <BootstrapNavbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto nav-links">
                    <Nav.Link as={Link} to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Nav.Link>
                    <Nav.Link as={Link} to="/about-us" className={location.pathname === '/about-us' ? 'active' : ''}>About Us</Nav.Link>
                    <Nav.Link as={Link} to="/services" className={location.pathname === '/services' ? 'active' : ''}>Services</Nav.Link>
                    <Nav.Link as={Link} to="/contact-us" className={location.pathname === '/contact-us' ? 'active' : ''}>Contact Us</Nav.Link>
                    {/* Add social media links here */}
                </Nav>
            </BootstrapNavbar.Collapse>
        </BootstrapNavbar>
    );
};

export default Navbar;
