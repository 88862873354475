export const images = [
    {
        src: "gallery/Collab-15.jpg",
    },
    {
        src: "gallery/Collab-26.jpg",
        
    },
    {
        src: "gallery/dholki-1.jpg",
        
    },
    {
        src: "gallery/dholki-2.jpg",
        
    },
    {
        src: "gallery/dholki-3.jpg",
        
    },
    {
        src: "gallery/dholki-4.jpg",
        
    },
    {
        src: "gallery/dholki-5.jpg",
        
    },
    {
        src: "gallery/dholki-home.png",
    }
]
