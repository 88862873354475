
import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import { Container, Row, Col} from "react-bootstrap";
import './contact-us.css';

const ContactUs = () => {

    return (
        <div className="main">
            <Navbar />
            <Container>
                <Row>
                    <h1 style={{ marginBottom: "5%" }} className="services-heading">Contact Us</h1>
                </Row>
                <Row style={{justifyContent:'center'}}>
                <Col xs={12} md={8} lg={6}>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc74eY1OzzUSuQDAAdXSbxy_HfYdYEzhdZdEB9b2KsaH_cKXw/viewform?embedded=true" width="100%" height="1761" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

export default ContactUs;
