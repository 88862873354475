import React from "react";
import { Container, Row, Col, Button} from "react-bootstrap";
import { Link} from 'react-router-dom';
import './Service.css';
const Service = ({image, title, description, link}) => {
    return (
        <Link to={'./' + link} style={{ textDecoration: 'none' }}>
            <Container fluid className="content">
                <Row>
                    <Col lg={{span:6, offset:1}} xs={{span:12}} className="service-text-content">
                        <h1 className="service-heading">{title}</h1>
                        <p className="description">{description}</p>     
                        {/* <Link to="https://www.tiktok.com/@hmr.decor"> <Button className="service-button">View More</Button> </Link> */}
                    </Col>
                    <Col lg={{span:4}} xs={{span:12}} className="image-container">
                        <img style={{width:400}} className="image" src={image}></img>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </Link>
    );
};

export default Service