import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import './home.css';
import { Container } from "react-bootstrap";
import { Gallery } from "react-grid-gallery";
import { images } from "../../assets/images";
import Footer from "../../components/Footer/Footer";
// Uncomment the following import to use the video in assets
// import videoBg from "../../assets/HMR_vid.MOV";

const videoBg = "/gallery/HMR_vid.MOV";

const Home = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkScreenSize(); // Initial check
        window.addEventListener('resize', checkScreenSize);

        return () => window.removeEventListener('resize', checkScreenSize);
    }, []);

    const slides = images.map(({ src, width, height }) => ({
        src: src,
        width,
        height,
    }));
        
    const [visibleItems, setVisibleItems] = useState(15);
    
    const loadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 7);
    };

    const [index, setIndex] = useState(-1);

    const handleClick = (index, item) => setIndex(index);

    const handlePlayVideo = () => {
        const video = document.getElementById("video-bg");
        video.play();
        setIsVideoPlaying(true);
    };

    return (
        <div className="main">
            <Navbar/>
            <Container fluid className="content-container main-bg">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-xs text-content">
                        <h1 className="heading">Welcome to HMR Decor</h1>
                        <p>Transform your dream wedding into reality with HMR Decor! Now accepting bookings for 2024 in the GTA. Our affordable and customizable decor packages ensure your special day is as unique as your love story. From classic elegance to modern chic, we’ve got you covered. Say “i do” to unforgettable moments and stress-free planning. Book now and let the magic begin!</p>     
                    </div>
                    <div className="col-lg-4 col-xs-12 video-container">
                        <video 
                            id="video-bg" 
                            className="video" 
                            src={videoBg} 
                            autoPlay={!isMobile}
                            loop 
                            muted 
                            playsInline
                            onPlay={() => setIsVideoPlaying(true)}
                        />
                        {isMobile && !isVideoPlaying && (
                            <button className="play-button" onClick={handlePlayVideo}>
                                Play Video
                            </button>
                        )}
                    </div>
                </div>
            </Container>
            <Container fluid style={{ paddingLeft: "5%", paddingRight: "5%" }}>
                <h1 className="heading-2">Crafting Moments, Creating Memories.</h1>
                <div className="gallery-container">
                    <Gallery
                        enableImageSelection={false}
                        rowHeight={300}
                        onClick={handleClick}
                        images={images.slice(0, visibleItems)}
                    />
                    {visibleItems < images.length && (
                        <button onClick={loadMore} className="load-more-button">
                            Load More
                        </button>
                    )}
                </div>
            </Container>
            <Lightbox
                index={index}
                open={index >= 0}
                close={() => setIndex(-1)}
                slides={slides}
            />
            <Footer/>
        </div>
    );
}

export default Home;
