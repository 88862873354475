import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import About from './pages/about us/about';
import Services from './pages/services/services';
import Dholki from './pages/dholki/dholki';
import Engagement from './pages/engagement/engagement';
import Divider from './pages/divider/divider';
import ContactUs from './pages/contact-us/contact-us';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/services/dholki" element={<Dholki />}></Route>
          <Route path="/services/engagement" element={<Engagement />}></Route>
          <Route path="/services/divider" element={<Divider />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
