
export const images = [
    {
        src: "gallery/blue-divider-1.jpg",
        
    },
    {
        src: "gallery/blue-divider-2.jpg",
        
    },
    {
        src: "gallery/blue-divider-3.jpg",
        
    },
    {
        src: "gallery/blue-divider-4.jpg",
        
    },
    {
        src: "gallery/purple-square-backdrop.jpg",
    },
    {
        src: "gallery/purple-backdrop.jpg",
    }
    

    
]
