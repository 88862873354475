import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import './services.css';
import { Container } from "react-bootstrap";
import Service from "../../components/Service/Service";
import Footer from "../../components/Footer/Footer";
const Services = () => {
    return (
        <div className="main">
            <Navbar/>
            <Container>
                <h1 className="services-heading"> Our Services </h1>
            </Container>
            <Service link={"dholki"} title={"Dholki"} description={"Elevate your dholki celebration to new heights with our exquisite dholki decor services. At HMR Decor, we specialize in creating vibrant and enchanting setups that set the perfect ambiance for your pre-wedding festivities."} image={"gallery/Collab-26.jpg"} />
            <Service link={"engagement"} title={"Engagement"} description={"Celebrate the beginning of your journey together with our exquisite engagement decor services. At HMR Decor, we understand that your engagement is a momentous occasion filled with love, joy, and cherished memories. Let us help you create a stunning backdrop for this special milestone in your life."} image={"gallery/Collab-4.jpg"} />
            <Service link={"divider"} title={"Nikkah Divider"} description={"Elevate your event space with our exquisite partition decor services. At HMR Decor, we understand the importance of creating stylish and functional partitions that enhance the ambiance of your venue."} image={"gallery/Collab-PinkSuit-32.jpg"} />
            <Footer/>
        </div>
    )
}

export default Services;