
export const images = [
    {
        src: "gallery/Collab-4.jpg"
    },
    {
        src: "gallery/Collab-PinkSuit-32.jpg",
        
    },
    {
        src: "gallery/Collab-PinkSuit-33.jpg",
        
    },
    {
        src: "gallery/Collab-PinkSuit-19.jpg",
        
    },
    {
        src: "gallery/purple-square-backdrop.jpg",
    },
    {
        src: "gallery/purple-backdrop.jpg",
    }
    ,
    {
        src: "gallery/pink-divider.jpg",
    },
    {
        src: "gallery/white-divider.jpg",
    }
    
]
