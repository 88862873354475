import Navbar from "../../components/Navbar/Navbar";
import { Container } from "react-bootstrap";
import Footer from "../../components/Footer/Footer";
import { Gallery } from "react-grid-gallery";
import { images } from "../../assets/divider-images";
import Lightbox from "yet-another-react-lightbox";
import React, { useState } from "react";
import './divider.css'
const Divider = () => {

    const slides = images.map(({ src, width, height }) => ({
        src: src,
        width,
        height,
      }));
        
    const [visibleItems, setVisibleItems] = useState(8);
    
    const loadMore = () => {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + 8);
    };
    const [index, setIndex] = useState(-1);

    const handleClick = (index, item) => setIndex(index);

    return (
        <div className="main">
            <Navbar/>
                <Container>
                    <h1 className="engagement-heading"> Here's some of our Nikkah Dividers! </h1>
                </Container>

                <Container>
                <div className="gallery-container">
                    <Gallery
                        enableImageSelection={false}
                        rowHeight={300}
                        onClick={handleClick}
                        images={images.slice(0, visibleItems)}
                    />
                    {visibleItems < images.length && (
                        <button onClick={loadMore} className="load-more-button">
                        Load More
                        </button>
                    )}
                </div>
            </Container>
                    <Lightbox
                        index={index}
                        open={index >= 0}
                        close={() => setIndex(-1)}
                        slides={slides}
                    />
            <Footer/>
        </div>
    )
}

export default Divider;