import Footer from "../../components/Footer/Footer";
import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import { Container, Row, Col } from "react-bootstrap";
import './about.css';

const About = () => {
    return (
        <div className="main">
            <Navbar />
            <Container>
                <Row className="text-center">
                    <Col>
                        <h1 className="about-us-heading">About Us</h1>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <p className="about-us-description">
                            Welcome to <strong>HMR Decor</strong>, your premier destination for exquisite wedding decor. Our mission is to transform your wedding day into a breathtakingly beautiful and unforgettable event. With a keen eye for detail and a passion for creating magical moments, we specialize in designing and crafting decor that captures the essence of your love story.
                        </p>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <h1 className="section-heading">Why Choose Us?</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="about-us-description">Choosing <strong>HMR Decor</strong> means choosing a partner who is as passionate about your wedding as you are. We are committed to providing:</p>
                        <ul className="about-us-list">
                            <li><strong>Personalized Service:</strong> Your wedding is unique, and we tailor our services to meet your specific needs and preferences.</li>
                            <li><strong>High-Quality Decor:</strong> We use only the finest materials and latest design trends to create decor that is both stylish and durable.</li>
                            <li><strong>Attention to Detail:</strong> We leave no stone unturned in ensuring that every element of your decor is perfect, from the largest centerpiece to the smallest detail.</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <h1 className="section-heading">Contact Us</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="about-us-description">Ready to bring your dream wedding to life? Contact us today to schedule a consultation and discover how we can create the perfect setting for your special day.</p>
                        <ul className="contact-info">
                            <li><strong>Email:</strong> hmrdecor.co@gmail.com</li>
                            <li><strong>Phone:</strong> (437)-982-1850</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <h1 className="section-heading">Our Social Media</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="about-us-description">Follow us on social media to see our latest creations and get inspired for your own wedding!</p>
                        <ul className="social-media-list">
                            <li><strong>Instagram:</strong> <Link to="https://www.instagram.com/hmr.decor/"> @hmr.decor </Link></li>
                            <li><strong>Tik Tok:</strong> <Link to="https://www.tiktok.com/@hmr.decor"> @hmr.decor </Link></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
}

export default About;
