
export const images = [
    {
        src: "gallery/Collab-4.jpg"
    },
    {
        src: "gallery/Collab-13.jpg",
    },
    {
        src: "gallery/Collab-PinkSuit-33.jpg",
        
    },
    {
        src: "gallery/blue-divider-1.jpg",
        
    },
    {
        src: "gallery/blue-divider-2.jpg",
        
    },
    {
        src: "gallery/blue-divider-3.jpg",
        
    },
    {
        src: "gallery/blue-divider-4.jpg",
        
    },
    {
        src: "gallery/Collab-15.jpg",
    },
    {
        src: "gallery/Collab-26.jpg",
        
    },
    {
        src: "gallery/dholki-1.jpg",
        
    },
    {
        src: "gallery/dholki-2.jpg",
        
    },
    {
        src: "gallery/dholki-3.jpg",
        
    },
    {
        src: "gallery/dholki-4.jpg",
        
    },
    {
        src: "gallery/dholki-5.jpg",
        
    },
    {
        src: "gallery/Collab-PinkSuit-19.jpg",
    },
    {
        src: "gallery/purple-square-backdrop.jpg",
    },
    {
        src: "gallery/purple-backdrop.jpg",
    }
    ,
    {
        src: "gallery/pink-divider.jpg",
    },
    {
        src: "gallery/white-divider.jpg",
    },
    {
        src: "gallery/dholki-home.png",
    }
]
