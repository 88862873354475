// Footer.jsx

import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <p>&copy; 2024 HMR Decor. All Rights Reserved.</p>
                <a className='icon' href="https://www.flaticon.com/free-icons/instagram-logo" title="instagram logo icons">Instagram logo icons created by Freepik - Flaticon</a>
            </div>
        </footer>
    );
}

export default Footer;
